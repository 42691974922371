.about-page {
    .image-zone {
        text-align: right;
        animation: fadeIn 1s 1.8s backwards;

        img {
            max-width: 40%;
            height: auto;
            border-radius: 20px;
            position: absolute;
            top: 50%; // Replace 'a%' with the desired vertical position
            left: 70%; // Replace 'b%' with the desired horizontal position
            transform: translate(-50%, -50%);
        }
    }

    @media screen and (max-width: 1200px) {
        .image-zone {

            img {
                // make it in the center
                left: 50%;
                max-width: 90%;
                margin-top: 80%;
            }
        }
    }
}