.contact-form {
    width: 100%;
    margin-top: 20px;
  
    ul {
      padding: 0;
      margin: 0;
  
      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
        border-radius: 8px;

      }
  
      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;
  
        &:first-child {
          margin-left: 0;
        }
      }
    }
  
    input[type='text'],
    input[type='email'] {
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
    }
  
    textarea {
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 20px;
      box-sizing: border-box;
      min-height: 150px;
      border-radius: 8px;
    }
  
    .flat-button {
      color: #ffd700;
      font-size: 11px;
      letter-spacing: 3px;
      text-decoration: none;
      padding: 8px 10px;
      border: 1px solid #ffd700;
      float: left;
      border-radius: 8px;
      background: 0 0;
      text-transform: uppercase;
      float: right;
      text-align: center;
      margin-right: 10px;
      &:hover {
        background: #ffd700;
        color: #333;
    }
    }
  }
  
  .map-wrap {
    // background: rgba(8, 253, 216, 0.1);
    background: #022c43;
    float: right;
    width: 53%;
    height: 115%;
    margin-top: -3%;
  }
  
  .leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
  }
  
  .info-map {
    position: absolute;
    background: #022c43; //qch
    border-radius: 10px;
    top: 50px;
    right: 30%;
    z-index: 999999;
    width: 267px;
    padding: 20px;
    color: #ffd700;
    font-family: 'sans-serif';
    font-size: 17px;
    font-weight: 500;
    opacity: 0;
    animation: fadeIn 1s 2s;
    animation-fill-mode: forwards;
  }
  
  @media screen and (max-width: 1200px) {
    .map-wrap {
      float: none;
      margin: 0;
      margin: 0 auto;
      width: 91%;
      height: 300px;
      margin-bottom: 30px;
      // make it ellipse
    }
    
    .info-map {
      display: none;
    }
  }