.home-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1 {
        color: #fff;
        font-size: 60px; //qch
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;
        line-height: normal; //qch

        &::before {
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

        &::after {
            content: '<h1/>';
            font-family: 'La Belle Aurore';
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 3s 4s backwards;
            opacity: 0.6;
        }

        img {
            // width: 32px;
            width: 45px; //qch
            margin-bottom: -5px; //qch
            margin-right: -8px; //qch
            margin-left: 10px; //qch
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }

    h2 {
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 13px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #ffd700;
        font-size: 14px;
        font-weight: 400;
        font-family: sans-serif;
        letter-spacing: 4px;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #ffd700;
            color: #333;
        }
    }

    .stage-cube-cont {
        width: 50%;
        height: 100%;
        top: 0;
        padding-top: 18%;
        margin-left: 0;
        position: absolute;
        right: 0;
        overflow: hidden;
    }

    .cubespinner {
        animation-name: spincube;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 12s;
        transform-style: preserve-3d;
        transform-origin: 100px 100px 0;
        margin-left: calc(50% - 100px);

        div {
            position: absolute;
            width: 200px;
            height: 200px;
            border: 1px solid #ccc;
            background: rgba(255, 255, 255, 0.4);
            text-align: center;
            font-size: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 0 20px 0px rgb(245, 226, 27);
        }

        .face1 {
            transform: translateZ(100px);
            color: #dd0031;
        }

        .face2 {
            transform: rotateY(90deg) translateZ(100px);
            color: #f06529;
        }

        .face3 {
            transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
            color: #28a4d9;
        }

        .face4 {
            transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
            color: #5ed4f4;
        }

        .face5 {
            transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
            color: #efd81d;
        }

        .face6 {
            transform: rotateX(-90deg) translateZ(100px);
            color: #ec4d28;
        }
    }

    @keyframes spincube {

        from,
        to {
            transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
        }

        16% {
            transform: rotateY(-90deg) rotateZ(90deg);
        }

        33% {
            transform: rotateY(-90deg) rotateX(90deg);
        }

        50% {
            transform: rotateY(-180deg) rotateZ(90deg);
        }

        66% {
            transform: rotateY(-270deg) rotateX(90deg);
        }

        83% {
            transform: rotateX(90deg);
        }
    }
}

@media screen and (max-width: 1200px) {

    .tags,
    .home-page h1::before,
    .home-page h1::after {
        display: none;
    }

    .home-page {
        .text-zone {
            position: initial;
            width: 100%;
            transform: none;
            padding: 10px;
            box-sizing: border-box;
        }

        .flat-button {
            float: none;
            display: block;
            margin: 20px auto 0 auto;
            width: 125px;
        }

        .stage-cube-cont {
            position: initial;
            width: 100%;
            height: 0%;
            overflow: visible;
            margin-top: -10px;
        }
    }
}